.services-container img {
	margin: auto;    
    display: block;
}

.services-container {
	padding-top: 20px;
}

.services-link, .services-link:hover {
	text-decoration: none;
}

.services-column {
	display: flex;
	justify-content: center;
	height: 150px;
	margin-bottom: 25px;
	margin-left: 0%;
}