.nav-brand {
	font-family: sofia-pro;
	font-weight: 600;
	font-style: normal;
	font-size: 34px;
	letter-spacing: 0em;
	line-height: 1em;
	text-transform: none;
	color: #e37263;
	outline: 0;
}

.nav {
	background-color: #f5f5f5;
	font-family: 'Nunito Sans';
	height: 165px;
}

.nav-social-link {
	height: 70px;
	padding: 20px;
	margin-left: 50px;
}

.tv-button {
	color: #e37263;
	border-color: #e37263;
	background-color: #ffffff;
	border-radius: 10em;
	padding: 10px;
	margin: 10px;
}

.tv-button:hover {
	color: #ffffff;
	background-color: #e37263;
	border-color: #e37263;
}

.tv-button:focus {
	color: #ffffff;
	background-color: #e37263;
	border-color: #e37263;
	box-shadow: 0 0 0 .2rem #dddddd;
}

.btn-primary:not(:disabled):not(.disabled):active {
	color: #ffffff;
	background-color: #e37263;
	border-color: #e37263;
}

.tv-button:active {
	color: #ffffff;
	background-color: #e37263;
	border-color: #e37263;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
	box-shadow: 0 0 0 .2rem #dddddd;
}

.nav-links {
	padding-right: 35px;
}

.nav-link {
	outline: 0;
}

.dropdown-item {
	outline: 0;
}

.dropdown-item.active {
	background: #363636;
}

.dropdown-item:active {
	background: #363636;
}