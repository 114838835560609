* {
  margin: 0;
}

html, body, #app, #app>div {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

body {
  background-color: #363636;
  font-family: 'Nunito Sans';
}

.loader {
  color: #cacaca;
  text-align: center;
  width: 100%;
}

.main-body {
  flex: 1 0 auto; 
  padding: 20px;
  background: #f5f5f5;
}