.footer {
	background-color: #363636;
	color: #ffffff;
	font-family: sofia-pro;
	font-style: normal;
	font-size: 15.25px;
	line-height: 1.55em;
	flex-shrink: 0;
	padding: 20px;
}

.footer-header-text {
	letter-spacing: .1em;
	font-size: 16px;
	font-weight: bolder;
	text-transform: uppercase;
}

.footer-link {
	color: #ffffff;
}

a.footer-link:hover {
	color: #ffffff;
}

.footer-row {
	width: 100%;
}

.footer-col {
	margin-bottom: 20px;
}

.social-icon {
	color: #ffffff;
	margin-top: 10px;
	margin-left: 10px;
	margin-right: 10px;
}