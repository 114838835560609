.brand-header {
	text-rendering: optimizeLegibility;
}

h2.brand-header {
	color: #4a4a4a;
	font-weight: 600;
	font-style: normal;
	font-size: 40px;
	letter-spacing: 0em;
	line-height: 1.2em;
	text-transform: none;
}

h3.brand-header {
	word-wrap: break-word;
	font-weight: bold;
	color: #e37263;
	font-style: normal;
	font-size: 15.25px;
	letter-spacing: .1em;
	line-height: 1.55em;
	text-transform: uppercase;
}

.brand-body {
	font-family: Alice;
	font-weight: 400;
	font-style: normal;
	font-size: 18px;
	letter-spacing: 0em;
	line-height: 1.8em;
	text-transform: none;
	color: #222;
}

@font-face {
	font-family:sofia-pro;
	src:url(https://use.typekit.net/af/7158ff/00000000000000003b9b1a9c/27/l?subset_id=2&fvd=n6&v=3) format("woff2"),
	url(https://use.typekit.net/af/7158ff/00000000000000003b9b1a9c/27/d?subset_id=2&fvd=n6&v=3) format("woff"),
	url(https://use.typekit.net/af/7158ff/00000000000000003b9b1a9c/27/a?subset_id=2&fvd=n6&v=3) format("opentype");
	font-weight:600;
	font-style:normal;
}

@font-face {
	font-family: 'Alice';
	font-style: normal;
	font-weight: 400;
	src: local('Alice Regular'), local('Alice-Regular'), url(https://fonts.gstatic.com/s/alice/v10/OpNCnoEEmtHa6GcOrg4.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }