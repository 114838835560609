:root {
	--service-circle-color: #0796b8;
}

.service-link {
	font-family: 'Nunito Sans';
	font-size: 16px;
	font-weight: bold;
	text-decoration: none;
	color: #ffffff;
	text-align: center;
	top: 50%;
    left: 50%;
	position: absolute;
	margin: 0px;
	transform: translate(-50%, -50%);
	outline: 0;
}

a.service-link:visited {
	text-decoration: none;
	color: var(--service-circle-color);
}

a.service-link:hover {
	text-decoration: none;
	color: var(--service-circle-color);
	font-weight: bold;
}

.circle { 
	display: block;
    width: 150px; 
	height: 150px;
	border-radius: 50%;
	background-origin: content-box;
	padding-top: 10px;
	padding-left: 10px;
	padding-bottom: 10px;
	padding-right: 10px;
	background-color: var(--service-circle-color);
    background-image: url(../flower.svg); 
    background-position:50% 50%;
	background-repeat:no-repeat;
	background-size: contain;
}

.circle:hover {
	display: block;
    width: 150px; 
	height: 150px;
	border-radius: 50%;
	background-color: var(--service-circle-color);
    background-position:50% 50%;
	background-repeat:no-repeat;
	background-size:contain;
}